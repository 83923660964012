<template>
	<div class="video-tem">
		<div class="video-group">
			<div class="local-group" :class="[{'only-local': !distalNum}]">
				<div v-show="videoInfo.type != '1'" id="local-player" class="player"></div>
			</div>
			<div class="remote-player">
				<div id="remote-inCustody" class="player-list"></div>
				<div id="remote-monitorVmUid" style="display: none; overflow: hidden;"></div>
			</div>
			<button class="btn-leave" id="leave" v-show="token" type="button" @click="closeMeeting()">挂断</button>
		</div>
	</div>
</template>

<script>
import AgoraRTC from 'agora-rtc-sdk-ng'
import { isEmpty } from '@/libs/util'

const client = AgoraRTC.createClient({
    mode: 'rtc',
    codec: 'vp8'
})

let remoteUsers = {}

export default {
    props: {
        videoInfo: {
            type: Object,
            default() {
                return {}
            }
        },
        isSubmit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showVideo: false,
            appid: '05a8015bf4d743d59f96cf779a4659dc',
            token: '',
            channel: '',
            uid: '',
            monitorVmUid: false,
            localTracks: {
                videoTrack: null,
                audioTrack: null
            },
            distalNum: 0
        }
    },
    watch: {
        videoInfo(n) {
            if (!isEmpty(n)) {
                this.token = n.token
                this.uid = n.uid
                this.channel = n.channelName
                this.join()
            } else {
                this.token = ''
                this.uid = ''
                this.channel = ''
            }
        }
    },
    methods: {
        /*
         * Add a user who has subscribed to the live channel to the local interface.
         *
         * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
         * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
         */
        handleUserPublished(user, mediaType) {
            const id = user.uid
            remoteUsers[id] = user
            this.subscribe(user, mediaType)
        },
        /*
         * Remove the user specified from the channel in the local interface.
         *
         * @param  {string} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to remove.
         */
        handleUserUnpublished(user) {
            const id = user.uid
            delete remoteUsers[id]

            this.distalNum--
            this.distalNum = this.distalNum > 0 ? this.distalNum : 0
            // document.querySelector(`#player-wrapper-${id}`).remove();
        },
        // 被踢出房间回调
        handleUserBanned() {
            this.$message.error('当前视频会见已被终止！')
            setTimeout(() => {
                this.funLeave()
            }, 1000)
        },
        /*
         * Join a channel, then create local video and audio tracks and publish them to the channel.
         */
        async join() {
            const _self = this
            // 订阅
            // 远端流加入房间
            client.on('user-published', _self.handleUserPublished)
            // 远端流离开房间
            client.on('user-unpublished', _self.handleUserUnpublished)
            // 被踢出房间
            client.on('connection-state-change', function (evt1, evt2, evt3) {
                // "DISCONNECTED": 连接断开。该状态表示用户处于以下任一阶段：
                // 	尚未通过 join 加入频道。
                // 	已经通过 leave 离开频道。
                // 	被踢出频道或者连接失败等异常情况。
                // "CONNECTING": 正在连接中。当调用 join 时为此状态。
                // "CONNECTED": 已连接。该状态表示用户已经加入频道，可以在频道内发布或订阅媒体流。
                // "RECONNECTING": 正在重连中。因网络断开或切换而导致 SDK 与服务器的连接中断，SDK 会自动重连，此时连接状态变为 "RECONNECTING"。
                // "DISCONNECTING": 正在断开连接。在调用 leave 的时候为此状态。
                console.error('sss', evt1, evt2, evt3)

                if (evt1 === 'DISCONNECTED') {
                    switch (evt3) {
                        // 当前频道被禁用
                        case 'CHANNEL_BANNED':
                            break
                        // 当前 IP 被踢出
                        case 'IP_BANNED':
                            break
                        // 用户正常退出
                        case 'LEAVE':
                            break
                        // 网络异常，经过重试后不可恢复
                        case 'NETWORK_ERROR':
                            break
                        // 服务端返回出现异常，通常是因为集成过程中参数有误
                        case 'SERVER_ERROR':
                            break
                        // 当前用户被踢出
                        case 'UID_BANNED':
                            _self.handleUserBanned()
                            break
                    }
                }
            })
            try {
                _self.uid = await client.join(_self.appid, _self.channel, _self.token || null, (_self.uid && parseInt(_self.uid, 10)) || null)
            } catch (err) {
                console.log('err', err)
                this.$message.error('加入房间失败，请稍后重试！')
                _self.$emit('close')
                return
            }
            // // create local tracks. Best practice is to use Promise.all and run them concurrently.
            // [_self.localTracks.audioTrack, _self.localTracks.videoTrack] = await Promise.all([
            // 	// Create tracks to the local microphone and camera.
            // 	AgoraRTC.createMicrophoneAudioTrack(),
            // 	AgoraRTC.createCameraVideoTrack()
            // ])
            try {
                _self.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
                _self.localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack()
            } catch (error) {
                this.$message.error('获取本地音频设备失败，请推出重新进入后重试！')
            }
            // Play the local video track to the local browser and update the UI with the user ID.
            _self.localTracks.videoTrack.play('local-player')
            // document.querySelector("#local-player-name").innerHTML = `localVideo(${_self.uid})`
            // Publish the local video and audio tracks to the channel.
            await client.publish(Object.values(_self.localTracks))
            console.log('publish success')
            _self.showVideo = true
        },

        /*
         * Stop all local and remote tracks then leave the channel.
         */
        async funLeave() {
            const { localTracks } = this

            for (let trackName in localTracks) {
                var track = localTracks[trackName]
                if (track) {
                    track.stop()
                    track.close()
                    localTracks[trackName] = undefined
                }
            }

            // Remove remote users and player views.
            remoteUsers = {}

            // leave the channel
            await client.leave()
            this.distalNum = 0
            this.showVideo = false
            // document.querySelector("#local-player-name").innerHTML = "";
            // document.querySelector("#join").attr("disabled", false);
            // document.querySelector("#leave").attr("disabled", true);
            console.log('client leaves channel success')

            document.querySelector('#remote-monitorVmUid').innerHTML = ''
            document.querySelector('#remote-inCustody').innerHTML = ''
            this.$emit('close')
        },

        /*
         * Add the local use to a remote channel.
         *
         * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
         * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
         */
        async subscribe(user, mediaType) {
            const uid = user.uid
            const { monitorVmUid, videoInfo } = this
            // 如果是管教id，直接return
            let isMonitorVm = (monitorVmUid && monitorVmUid == uid) || false
            // subscribe to a remote user
            await client.subscribe(user, mediaType)

            this.showVideo = true
            if (mediaType === 'video') {
                // <p class="player-name">remoteUser(${uid})</p>
                const player = new DOMParser().parseFromString(
                    `
						<div style="height: 100%" class="player-wrapper" id="player-wrapper-${uid}">
							<div style="height: 100%; ${videoInfo.type == '1' ? 'display: none' : ''}" class="player" id="player-${uid}" class="player"></div>
						</div>
					`,
                    'text/html'
                )
                let node = player.getElementsByTagName('div')[0]
                // document.querySelector("#remote-playerlist").appendChild(node);
                if (isMonitorVm) {
                    document.querySelector('#remote-monitorVmUid').innerHTML = ''
                    document.querySelector('#remote-monitorVmUid').appendChild(node)
                } else {
                    document.querySelector('#remote-inCustody').innerHTML = ''
                    document.querySelector('#remote-inCustody').appendChild(node)
                }
                user.videoTrack.play(`player-${uid}`)
            }
            if (mediaType === 'audio') {
                user.audioTrack.play()
            }
            this.distalNum++
        },
        // 关闭会见
        closeMeeting() {
            if (!this.isSubmit) return this.$message.error('请填写服务记录后再挂断')

            this.$confirm('确定要结束会见吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message.error('会见结束！')
                this.funLeave()
            })
        }
    }
}
</script>

<style lang="less" scope>
.video-tem {
    position: relative;
    flex: 1;
    border-radius: 20px;
    overflow: hidden;

    input {
        border: 1px solid #ddd;
        height: 36px;
    }
    .video-group {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .local-group {
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        height: 30%;
        z-index: 2;
        // background: url(../../static/images/videoTrack.png) center center no-repeat #ddd;
        // background-size: 30%;
        &.only-local {
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
        }
    }
    .remote-player {
        z-index: 1;
        width: 100%;
        height: 100%;
        .player-list {
            width: 100%;
            height: 100%;
            // background: url(../../static/images/videoTrack.png) center center no-repeat #1a2b42;
            // background-size: 30%;
        }
    }
    .player {
        width: 100%;
        height: 100%;
    }
    .btn-leave {
        position: absolute;
        bottom: 16px;
        width: 80%;
        height: 44px;
        left: 10%;
        background: #ff5a5a;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 44px;
        z-index: 3;
        cursor: pointer;
    }
}
</style>

<style lang="less">
.player-list {
    position: relative;

    .player-wrapper {
        height: 100%;
    }
}
</style>
