<template>
    <div class="index" v-if="isLoad">
        <div class="top fbox flex-ac">
            <h1 class="logo">监优服律师中心</h1>
            <h2 class="type flex1">在线视频会见</h2>
            <div class="user fbox flex-ac">
                <div class="pic">
                    <img class="uimg" :src="lawyerInfo.practiceImage" alt="">
                </div>
                <el-dropdown class="name" @command="btnDrop">
                    <span class="el-dropdown-link">
                        {{lawyerInfo.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="out">登出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="main fbox">
            <div class="one" :class="{'flex1': !hasLeft}">
                <p class="title">待接入家属会见</p>
                <!-- <div class="box bg">
                    <div class="pic"></div>
                    <div class="flex1">
                        <div class="name">张三 <span class="wait">已等待20分钟</span></div>
                        <div class="time">呼入时间：2021-01-24 14:33:00</div>
                    </div>
                    <div class="link">正在视频通话中…</div>
                </div>
                <div class="box bb">
                    <div class="pic"></div>
                    <div class="flex1">
                        <div class="name">张三 <span class="wait">已等待20分钟</span></div>
                        <div class="time">呼入时间：2021-01-24 14:33:00</div>
                    </div>
                    <div class="btn"><span class="tel"></span>接入</div>
                </div> -->
                <template v-if="hasLeft">
                    <div class="box" v-for="(son, index) in leftList" :key="son.userId">
                        <div class="pic">
                            <img class="oimg" :src="son.headUrl" v-if="son.headUrl">
                        </div>
                        <div class="flex1">
                            <div class="name">{{son.userName}} <span class="wait" v-if="son.waitDate">{{son.waitDate}}</span></div>
                            <div class="time">呼入时间：{{son.lineUpDate}}</div>
                        </div>
                        <template v-if="!index">
                            <div class="link" v-if="son.userId == linkUserId">正在视频通话中…</div>
                            <div class="btn" v-else @click="btnIn(son)"><span class="tel"></span>接入</div>
                        </template>
                    </div>
                </template>
                <p class="nodata" v-else>暂无家属会见</p>
            </div>
            <div class="two flex1" v-if="hasLeft">
                <comMeet :videoInfo="videoInfo" :isSubmit="isSubmit" @close="backClose"></comMeet>
            </div>
            <div class="three" v-if="hasLeft">
                <el-scrollbar wrapStyle="overflow-x: hidden;" viewStyle="padding: 0 20px 50px;" :native="false" :noresize="false">
                    <p class="title">服务记录</p>
                    <div class="box">
                        <p class="left">家属姓名</p>
                        <p class="right">{{meetInfo.userName}}</p>
                    </div>
                    <div class="box">
                        <p class="left">会见律师</p>
                        <p class="right">{{lawyerInfo.name}}</p>
                    </div>
                    <div class="box">
                        <p class="left">所属律所</p>
                        <p class="right">{{lawyerInfo.lawofficeName}}</p>
                    </div>
                    <div class="box">
                        <p class="left">资讯类型</p>
                        <div class="list fbox">
                            <p class="sel" :class="{'seled': son.selected}" v-for="son in typeList" :key="son.label" @click="btnType(son)">{{son.label}}</p>
                        </div>
                    </div>
                    <div class="box">
                        <p class="left">在押人姓名</p>
                        <el-input class="flex1" v-model="username" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="box">
                        <p class="left">关押城市</p>
                        <el-select v-model="cityId" placeholder="请选择">
                            <el-option v-for="son in cityList" :key="son.id" :label="son.name" :value="son.id"></el-option>
                        </el-select>
                    </div>
                    <div class="box">
                        <p class="left">关押监所</p>
                        <el-select v-model="prisonId" placeholder="请选择">
                            <el-option v-for="son in prisonList" :key="son.code" :label="son.name" :value="son.code"></el-option>
                        </el-select>
                    </div>
                    <div class="box">
                        <p class="left">证件类型</p>
                        <el-select v-model="cardType" placeholder="请选择">
                            <el-option v-for="son in cardTypeList" :key="son.value" :label="son.label" :value="son.value"></el-option>
                        </el-select>
                    </div>
                    <div class="box">
                        <p class="left">证件号</p>
                        <el-input class="flex1" maxlength="18" v-model="idCard" placeholder="请输入内容"></el-input>
                    </div>
                    <div class="box">
                        <p class="left">服务意向</p>
                        <el-radio-group v-model="serviceIntent">
                            <el-radio :label="1">有</el-radio>
                            <el-radio :label="2">无</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="tbox b1">
                        <p class="left">咨询描述</p>
                        <textarea class="area" v-model="detail" placeholder="请输入家属咨询问题"></textarea>
                    </div>
                    <div class="tbox mb30">
                        <p class="left">咨询情况</p>
                        <textarea class="area" v-model="consultDesc" placeholder="请总结家属咨询情况"></textarea>
                    </div>
                    <div class="fbox flex-je">
                        <div class="btn" v-loading="btnLoading" @click="btnSubmit">提交</div>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/libs/api'
import { isEmpty, isIDCard } from '@/libs/util'
import comMeet from './components/meet'
import cookie from 'js-cookie'
import { mapMutations } from 'vuex'

export default {
    data() {
        return {
            typeList: [
                {
                    label: '律师咨询',
                    value: 5,
                    selected: true
                },
                {
                    label: '家属会见',
                    value: 1,
                    selected: false
                },
                {
                    label: '律师会见',
                    value: 3,
                    selected: false
                },
                {
                    label: '其他',
                    value: 4,
                    selected: false
                }
            ],
            cardTypeList: [
                {
                    label: '身份证',
                    value: 0
                },
                {
                    label: '护照',
                    value: 1
                },
                {
                    label: '其他',
                    value: 2
                }
            ],
            btnLoading: false,
            isLoad: false,
            monitoringCenterNo: '',
            username: '',
            cityId: '',
            prisonId: '',
            cardType: 0,
            idCard: '',
            serviceIntent: 1,
            detail: '',
            consultDesc: '',
            userInfo: {},
            lawyerInfo: {},
            officeInfo: {},
            hasLeft: true,
            leftList: [],
            linkUserId: '',
            cityList: [],
            prisonList: [],
            videoInfo: {},
            cInterval: null,
            cTime: null,
            meetInfo: {},
            isSubmit: false,
            cLeft: null
        }
    },
    components: {
        comMeet
    },
    created() {
        this.getTwo()
        this.getCityList()
    },
    mounted() {
        window.addEventListener('beforeunload', () => {
            window.localStorage.setItem('beforeunload-test', new Date().getTime())
            if (this.meetInfo.userId) {
                this.backClose()
            }
        })
    },
    watch: {
        cityId() {
            this.getPrisonList()
        }
    },
    methods: {
        ...mapMutations(['setToken']),
        getTwo() {
            let p1 = this.$http({
                url: api.getlawyerinfo,
                method: 'get'
            })

            let p2 = this.$http({
                url: api.roleInfo,
                method: 'get'
            })

            Promise.all([p1, p2])
                .then(result => {
                    let res1 = result[0]
                    let res2 = result[1]

                    if (res1.status === 200 && !isEmpty(res1.data)) {
                        this.lawyerInfo = res1.data.lawyer
                        this.officeInfo = res1.data.lawOffice
                    }

                    if (res2.status === 200 && !isEmpty(res2.data)) {
                        this.userInfo = res2.data
                        this.getLeftList()
                        this.setSocket()
                        this.cLeft = setInterval(() => {
                            this.getLeftList()
                        }, 5000)
                    } else {
                        this.$message.error('获取登录信息失败，请重新登录')
                        this.$router.replace({
                            name: 'login'
                        })
                    }
                })
                .catch(err => {
                    console.warn(err)
                    this.$message.error('请重新登录')
                    this.$router.replace({
                        name: 'login'
                    })
                })
        },
        getLeftList() {
            this.$http({
                url: api.queryFamily,
                method: 'get',
                data: {
                    lawyerId: this.lawyerInfo.id,
                    current: 1,
                    size: 999
                }
            })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        this.leftList = result.data.records
                        this.hasLeft = !!result.data.records.length

                        if (!this.isLoad) {
                            this.isLoad = true
                        }
                    } else {
                        this.hasLeft = false
                    }
                })
                .catch(err => {
                    console.warn(err)
                    this.hasLeft = false
                })
        },
        setSocket() {
            let ws = new WebSocket(`${api.ws}/api/yqs-chat/ws/online/${this.userInfo.id}`)

            //当WebSocket创建成功时，触发onopen事件
            ws.onopen = () => {
                let obj = { msg: 'ping' }
                ws.send(JSON.stringify(obj))

                this.cInterval = setInterval(() => {
                    //将消息发送到服务端
                    ws.send(JSON.stringify(obj))
                }, 5000)
            }

            //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
            ws.onerror = () => {
                // 一分钟后重新尝试连接
                this.cTime = setTimeout(() => {
                    this.setSocket()
                }, 60000)
            }
        },
        getCityList() {
            this.$http({
                url: api.cityInService,
                method: 'get'
            })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        this.cityList = Object.values(result.data).reduce((tar, son) => {
                            return tar.concat(son)
                        }, [])
                    }
                })
                .catch(err => {
                    console.warn(err)
                })
        },
        getPrisonList() {
            this.$http({
                url: api.getSupervisionCenter,
                method: 'get',
                data: {
                    cityId: this.cityId
                }
            })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        this.monitoringCenterNo = result.data.code

                        return this.$http({
                            url: api.getPrisonList,
                            method: 'get',
                            data: {
                                centerId: result.data.code
                            }
                        })
                    }
                })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        this.prisonList = result.data
                    } else {
                        this.prisonList = []
                        this.prisonId = ''
                    }
                })
                .catch(err => {
                    this.prisonList = []
                    this.prisonId = ''
                    console.warn(err)
                })
        },
        btnIn(value) {
            if (!isEmpty(this.meetInfo)) return this.$message.error('请填写服务记录')

            this.meetInfo = value
            this.username = ''
            this.cityId = ''
            this.prisonId = ''
            this.cardType = 0
            this.idCard = ''
            this.serviceIntent = 1
            this.detail = ''
            this.consultDesc = ''
            this.isSubmit = false

            this.typeList.forEach((son, index) => {
                son.selected = !index
            })

            this.$http({
                url: api.agoraToken,
                method: 'get',
                data: {
                    lawyerId: this.lawyerInfo.id,
                    vmFamilyUserId: value.userId
                }
            })
                .then(result => {
                    if (result.status === 200 && !isEmpty(result.data)) {
                        this.videoInfo = result.data
                        this.linkUserId = value.userId
                    } else {
                        this.videoInfo = {}
                        this.linkUserId = ''
                    }
                })
                .catch(err => {
                    console.warn(err)
                    this.videoInfo = {}
                    this.linkUserId = ''
                })
        },
        backClose() {
            this.videoInfo = {}
            this.linkUserId = ''
            this.isSubmit = false

            this.$http({
                url: api.lineupEnd,
                method: 'post',
                data: {
                    lawyerId: this.lawyerInfo.id,
                    userId: this.meetInfo.userId
                }
            })
                .then(() => {
                    this.getLeftList()
                    this.meetInfo = {}
                })
                .catch(err => console.warn(err))
        },
        btnSubmit() {
            if (this.btnLoading) return false
            if (!this.username) return this.$message.error('请输入在押人姓名')
            if (!isIDCard(this.idCard)) return this.$message.error('请输入正确的身份证号码')

            let typeObj = this.typeList.find(son => son.selected)
            let cityObj = this.cityList.find(son => son.id === this.cityId)
            let priObj = this.prisonList.find(son => son.code === this.prisonId)

            let params = {
                scope: 32,
                type: [typeObj.value],
                idType: this.cardType,
                username: this.username,
                idCard: this.idCard,
                detail: this.detail,
                consultDesc: this.consultDesc,
                serviceIntent: this.serviceIntent,
                adress: cityObj.pid,
                prisonCityCode: cityObj.id,
                prisonNo: priObj.code,
                prisonAddress: priObj.address,
                prisonName: priObj.name,
                monitoringCenterNo: this.monitoringCenterNo,
                familyUserId: this.meetInfo.userId,
                lawOfficeId: this.officeInfo.id
            }

            this.btnLoading = true

            this.$http({
                url: api.advisoryOrderAdd,
                method: 'post',
                data: params
            })
                .then(result => {
                    if (result.status == 200) {
                        this.$message.success('保存成功')
                        this.isSubmit = true
                    } else {
                        this.$message.error(result.msg)
                    }
                    this.btnLoading = false
                })
                .catch(err => {
                    console.warn(err)
                    this.$message.error('保存失败，请重试')
                    this.btnLoading = false
                })
        },
        btnType(value) {
            for (const son of this.typeList) {
                son.selected = false
            }
            value.selected = true
        },
        btnDrop(value) {
            if (value === 'out') {
                this.$http({
                    url: api.logout,
                    method: 'post'
                })
                    .then(result => {
                        if (result.status === 200) {
                            this.$message.success('登出成功')
                            this.setToken('Basic bGF3eWVyLWFwcDpzeXBmMjAyMCM=')
                            cookie.remove('lc_token')
                            clearInterval(this.cInterval)
                            clearTimeout(this.cTime)
                            clearInterval(this.cLeft)

                            this.$nextTick(() => {
                                this.$router.replace({
                                    name: 'login'
                                })
                            })
                        } else {
                            this.$message.error(result.msg)
                        }
                    })
                    .catch(err => {
                        this.$message.error('登出失败，请重试')
                        console.warn(err)
                    })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.index {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(180deg, #131412 60%, #ecedee 40%);
    height: 100vh;
    padding: 0 20px;
    line-height: 1;

    .top {
        height: 74px;
        border-bottom: 1px solid #2f2f2d;
        color: #fff;

        .logo {
            width: 256px;
            font-size: 20px;
        }

        .type {
            background: url(~@/assets/images/top.png) left center no-repeat;
            background-size: 20px 20px;
            padding-left: 32px;
            font-size: 16px;
        }

        .user {
            .pic {
                width: 36px;
                height: 36px;
                margin-right: 12px;
                border-radius: 50%;
                overflow: hidden;

                .uimg {
                    width: 36px;
                    height: 36px;
                }
            }

            .name {
                cursor: pointer;
                font-size: 16px;
                color: #fff;
            }
        }
    }
    .main {
        flex: 1;
        padding: 20px 0;
        overflow: hidden;
    }
    .com-box() {
        background-color: #fff;
        border-radius: 20px;
    }

    .one {
        .com-box;
        width: 480px;

        .title {
            padding-left: 20px;
            line-height: 56px;
            font-size: 16px;
        }

        .box {
            display: flex;
            align-items: center;
            padding: 16px 20px;

            .pic {
                width: 48px;
                height: 48px;
                margin-right: 20px;
                border-radius: 4px;
                overflow: hidden;

                .oimg {
                    width: 48px;
                    height: 48px;
                }
            }

            .name {
                margin-bottom: 12px;
                font-size: 16px;

                .wait {
                    background: url(~@/assets/images/wait.png) left center no-repeat;
                    background-size: 16px 16px;
                    padding-left: 22px;
                    margin-left: 12px;
                    font-size: 14px;
                    color: #9798a0;
                }
            }

            .time {
                font-size: 14px;
            }

            .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #05a9a5;
                width: 120px;
                height: 40px;
                border-radius: 5px;
                text-align: center;
                cursor: pointer;
                font-size: 14px;
                color: #fff;

                .tel {
                    background: url(~@/assets/images/tel.png) 0 0 no-repeat;
                    background-size: 18px 18px;
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }

            .link {
                height: 48px;
                font-size: 14px;
                color: #ff5a5a;
            }
        }

        .bg {
            background-color: #f7f7f8;
        }

        .bb {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                left: 20px;
                right: 20px;
                top: 0;
                background-color: #f2f2f4;
                height: 1px;
            }
        }
    }

    .two {
        .com-box;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
    }

    .three {
        .com-box;
        display: flex;
        flex-direction: column;
        width: 480px;
        overflow: hidden;

        .title {
            line-height: 56px;
            font-size: 16px;
        }

        .box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 52px;
            border-bottom: 1px solid #f2f2f4;
            font-size: 14px;

            .left {
                color: #9798a0;
            }

            .list {
                .sel {
                    height: 22px;
                    padding: 0 8px;
                    margin-left: 12px;
                    border: 1px solid #dedee2;
                    border-radius: 4px;
                    line-height: 20px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #31323a;
                }
                .seled {
                    background-color: #31323a;
                    border-color: #31323a;
                    color: #fff;
                }
            }
        }

        .tbox {
            padding: 20px 0;
            font-size: 14px;

            .left {
                padding-bottom: 14px;
                color: #9798a0;
            }

            .area {
                background-color: #eee;
                width: 100%;
                height: 88px;
                padding: 15px;
                border-radius: 4px;
            }
        }

        .b1 {
            border-bottom: 1px solid #f2f2f4;
        }

        .mb30 {
            margin-bottom: 30px;
        }

        .btn {
            background-color: #31323a;
            width: 120px;
            height: 40px;
            border-radius: 5px;
            text-align: center;
            line-height: 40px;
            cursor: pointer;
            font-size: 14px;
            color: #fff;
        }
    }

    .nodata {
        background: url(~@/assets/images/none.png) center 100px no-repeat;
        background-size: 470px 370px;
        padding-top: 440px;
        text-align: center;
        font-size: 16px;
        color: #c2c3c8;
    }
}
</style>
